import React from "react"
import Logo from "../common/utils/logo"
import Renderer from "./editor/renderer"
import QuotationStage from "./elements/stage"

const QuotationLayout = ({ quotation }) => {
  return <Renderer input={quotation} />
}

export default QuotationLayout
